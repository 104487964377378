import ApiClient from '@/libs/apiClient'

export default {
  getPriceChangePdf(payload) {
    return ApiClient.post('protocol/price-change', payload)
  },
  getScrappingPdf(payload) {
    return ApiClient.post('protocol/scrapping', payload)
  },
}
