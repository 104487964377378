<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          <div
            style="cursor:pointer; margin-bottom: 30px;"
            @click="refreshPage"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="24"
            />
          </div> Napi rohadás arány
        </b-card-title>
        <div class="font-small-2">
          Forintban kifejezve
        </div>
        <h2 class="mb-1">
          {{ data.rottenPrice.toLocaleString() }} Ft
        </h2>
        <!--        <b-card-text class="text-muted font-small-2">-->
        <!--          <span class="font-weight-bolder">68.2%</span><span> több rohadás volt az előző hónaphoz képest.</span>-->
        <!--        </b-card-text>-->
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="data.chart"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = 'rgb(1,157,56)'
const $earningsStrokeColor3 = 'rgba(255,0,0,0.75)'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          labels: [`Termékek: ${this.data.chart[0]}%`, `Rohadás: ${this.data.chart[1]}%`],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: false,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Rohadás',
                    formatter() {
                      return ''
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
  },
}
</script>
