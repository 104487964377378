<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <div class="scrappingContainer card-body">
          <h3>Selejtezési jegyzőkönyv</h3>
          <div>
            <input
              v-model="scrapping.from"
              type="date"
              name="from"
              class="date"
            >
            <input
              v-model="scrapping.to"
              type="date"
              name="to"
              class="date"
              :min="scrapping.from"
              :disabled="scrapping.from === null"
            >
          </div>
          <button
            @click="getScrappingPdf"
          >
            Elkészítés
          </button>
        </div>
      </b-col>
      <b-col>
        <div class="priceChangeContainer card-body">
          <h3>Árváltozás</h3>
          <div>
            <input
              v-model="priceChange.from"
              type="date"
              name="from"
              class="date"
            >
            <input
              v-model="priceChange.to"
              type="date"
              name="to"
              class="date"
              :min="priceChange.from"
              :disabled="priceChange.from === null"
            >
          </div>
          <button @click="getPriceChangePdf">
            Elkészítés
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Raktár bevétel -->
      <b-col
        v-if="Object.keys(getWarehouseSales).length > 0"
        lg="7"
      >
        <ecommerce-company-table :table-data="getWarehouseSales" />
      </b-col>
      <!--/ Raktár bevétel -->

      <!-- Rohadás -->
      <b-col
        v-if="Object.keys(getRotting).length > 0"
        lg="5"
      >
        <ecommerce-earnings-chart :data="getRotting" />
      </b-col>
      <!--/ Rohadás -->
    </b-row>
    <b-row>
      <b-col v-if="Object.keys(getWarehouseSalesBetweenDates).length > 0">
        <div class="chartHeader">
          <h1>Eladási statisztika</h1>
          <select
            v-model="chartForWarehouse"
            class="select forWarehouse"
            @change="fetWarehouseSaleWithNewParams"
          >
            <option value="null">
              Összes raktár
            </option>
            <option
              v-for="warehouse in allWarehouses"
              :key="warehouse.id"
              :value="warehouse.id"
            >
              {{ warehouse.name }}
            </option>
          </select>
          statisztikája
          <div class="py-2">
            <input
              v-model="chart.from"
              type="date"
              name="from"
              class="date mr-1"
              @change="resetTo"
            >
            <input
              v-model="chart.to"
              type="date"
              name="to"
              class="date"
              :min="chart.from"
              :disabled="chart.from === null"
              @change="fetchWithDatePicker"
            >
          </div>
        </div>
        <Chart
          :data="getWarehouseSalesBetweenDates"
          :y-axes-max="getWarehouseSalesBetweenDates.maxYAxis"
        />
      </b-col>
    </b-row>

    <b-row class="py-3">
      <b-col>
        <div class="closingHistory">
          <div>
            <input
              v-model="closingHistory.from"
              type="date"
              name="from"
              class="date"
            >
            <input
              v-model="closingHistory.to"
              type="date"
              name="to"
              class="date"
              :min="closingHistory.from"
              :disabled="closingHistory.from === null"
            >
            <select
              v-model="closingHistory.warehouse_id"
              class="select"
            >
              <option value="null">
                Összes raktár
              </option>
              <option
                v-for="warehouse in allWarehouses"
                :key="warehouse.id"
                :value="warehouse.id"
              >
                {{ warehouse.name }}
              </option>
            </select>
          </div>
          <button @click="fetchClosingHistory">
            Lekérdezés
          </button>
        </div>
        <b-overlay>
          <vue-good-table
            :columns="columns"
            :rows="getClosingHistory"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: quantity -->
              <span v-if="props.column.field === 'quantity'">
                <div
                  v-for="(item, index) in props.row.quantity"
                  :key="index"
                  style="font-size:small; font-weight:bold"
                >
                  {{ item.in }}: {{ item.value }} {{ item.unit }}
                </div>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'products-edit', params: { id: props.row.id } }"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Szerkesztés</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="deleteProductConfirm(props.row.id)">
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Törlés</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Jelenleg </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    találat a(z) {{ props.total }} -ból / ből
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BOverlay, BPagination, BFormSelect,
} from 'bootstrap-vue'

import { mapGetters, mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
// import flatPickr from 'vue-flatpickr-component'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import protocolService from '@/services/protocolService'
import Chart from '@/views/dashboard/Chart.vue'

import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    Chart,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Zárást végezte',
          field: 'user.name',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Zárást végezte keresés',
          },
        },
        {
          label: 'Ebben a raktárban',
          field: 'warehouse.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Raktár keresése',
          },
        },
        {
          label: 'Bevétel',
          field: 'total_amount',
          filterOptions: {
            enabled: true,
            placeholder: 'Bevétel keresés',
          },
        },
        {
          label: 'Készpénzes fizetés',
          field: 'cash',
          filterOptions: {
            enabled: true,
            placeholder: 'Készpénzes fizetés keresés',
          },
        },
        {
          label: 'Kártyás fizetés',
          field: 'card',
          filterOptions: {
            enabled: true,
            placeholder: 'Kártyás fizetés keresés',
          },
        },
        {
          label: 'Megadott összeg',
          field: 'cash_from_cashier',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Megadott összeg keresés',
          },
        },
        {
          label: 'Eredmény',
          field: 'deficit',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Eredmény keresés',
          },
        },
        {
          label: 'Zárás ekkor',
          field: 'created_at.timestamp',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Zárás keresés',
          },
        },
      ],
      loading: false,
      searchTerm: '',
      scrapping: {
        from: null,
        to: null,
      },
      priceChange: {
        from: null,
        to: null,
      },
      setWarehouseId(id) {
        this.warehouse_id = id
      },
      closingHistory: {
        from: null,
        to: null,
        warehouse_id: null,
      },
      warehouseSalesBetweenDates: {
        from: null,
        to: null,
      },
      chart: {
        from: null,
        to: null,
      },
      chartForWarehouse: null,
      rangePicker: [],
    }
  },
  computed: {
    ...mapState(['statistics', 'dailyClosing', 'warehouses']),
    ...mapGetters(['getWarehouseSales', 'getRotting', 'getClosingHistory', 'allWarehouses', 'getWarehouseSalesBetweenDates']),
  },
  mounted() {
    this.$store.dispatch('fetchWarehouseSales')
    this.$store.dispatch('fetchRotting')
    this.$store.dispatch('fetchClosingHistory', this.closingHistory)
    this.$store.dispatch('fetchWarehouses')
    this.$store.dispatch('fetchWarehouseSalesBetweenDates', {})
  },

  methods: {
    async getScrappingPdf() {
      const serverResponse = await protocolService.getScrappingPdf(this.scrapping)
      await window.open(serverResponse.data.data.url, '_blank')
    },
    async getPriceChangePdf() {
      const serverResponse = await protocolService.getPriceChangePdf(this.priceChange)
      await window.open(serverResponse.data.data.url, '_blank')
    },
    async fetchClosingHistory() {
      await this.$store.dispatch('fetchClosingHistory', {
        from: this.closingHistory.from,
        to: this.closingHistory.to,
        warehouse_id: this.closingHistory.warehouse_id,
      })
    },
    resetTo() {
      if (this.chart.to.length !== null) {
        this.chart.to = null
      }
    },
    async fetchWithDatePicker() {
      await this.$store.dispatch('fetchWarehouseSalesBetweenDates', { warehouse_id: this.chartForWarehouse, from: this.chart.from, to: this.chart.to })
    },
    async fetWarehouseSaleWithNewParams() {
      if (this.chartForWarehouse !== null && this.chart.to !== null) {
        await this.$store.dispatch('fetchWarehouseSalesBetweenDates', { warehouse_id: this.chartForWarehouse, from: this.chart.from, to: this.chart.to })
      } else {
        await this.$store.dispatch('fetchWarehouseSalesBetweenDates', { warehouse_id: this.chartForWarehouse })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.scrappingContainer,
.priceChangeContainer,
.closingHistory {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: white;

  .date,
  .select {
    border: thin solid rgba(0, 0, 0, .21);
    margin: 5px;
    padding: 3px;
    border-radius: 4px;
  }

  button {
    background-color: #245d15;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0 0;
  }
  .chartHeader{
    display:flex;
    flex-wrap:wrap;
    flex-direction: row;
  }
}
</style>
